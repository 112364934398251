import React, { useEffect, useState } from "react";
import axios from "axios";
// import MainLogo from "../../../src/assets/image/main-logo.png";
import { useLocation } from "react-router-dom";
import Footer from "../superAdmin/footer/Footer";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import moment from "moment";
import MainLogo from "../../assets/image/logo-image.png";

import { Spinner } from "react-bootstrap";
import TokenUndefined from "../Service/TokenUndefined";
import { message } from "antd";
import FormatChecker from "../Service/FormatChecker";
import DatePickers from "../../common/DatePickers";
import DateTimerPicker from "../../common/DateTimerPicker";
// import DatePickers from "..//../ /common/DatePickers";
// import DateTimerPicker from "../common/DateTimerPicker";

import "./bodycs.css"; // Custom CSS for rotation
// const isDateInRange = (date) => {
//   const startDate = new Date("2024-07-029T00:00:00");
//   const endDate = new Date("2024-08-04T23:59:59");
//   const givenDate = new Date(date);

//   return givenDate >= startDate && givenDate <= endDate;
// };
const isDateInRange = (date) => {
  const startDate = new Date("2024-07-29T00:00:00"); // Corrected date
  const endDate = new Date("2024-08-04T23:59:59");
  const givenDate = new Date(date);

  return givenDate >= startDate && givenDate <= endDate;
};
const BuyerRegistration = ({
  formHeader,
  formName,
  editStatus,
  companyType,
  edit_name,
  edit_contact,
  edit_phone,
  edit_email,
  id,
  barcode,
  barcodeId,
}) => {
  let editName = edit_name === undefined ? "" : edit_name;
  let editContact = edit_contact === undefined ? "" : edit_contact;
  let editPhone = edit_phone === undefined ? "" : edit_phone;
  let editEmail = edit_email === undefined ? "" : edit_email;
  const [formStatus, setFormStatus] = useState(false);
  console.log(
    "sdasdadadadadads0",
    editName,
    editContact,
    editPhone,
    editEmail,
    companyType
  );
  const navigate = useNavigate();

  let currentLocation = useLocation().pathname;

  const designationArray = [
    "Owner/propreitor",
    "Partner",
    "Director",
    "Procurement/Purchase head",
    "Other",
  ];
  const travelArray = ["Train", "Flight", "Self"];
  const visitingAsArray = [
    "Wholesaler",
    "Manufacturer",
    "Retailer",
    "Chain Store   ",
    "Agent/Broker",
  ];
  const [visitorName, setVisitorName] = useState(editContact);
  const [designation, setDesignation] = useState("");

  const [visiting_as, setVisiting_as] = useState("");
  const [yourCompany, setYourCompany] = useState();

  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState(editEmail);
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [country, setCountry] = useState("India");
  const [hearAbout, setHearAbout] = useState("");
  const [photo, setPhoto] = useState();
  const [idProofFront, setIDProofFront] = useState();
  const [idProofBack, setIDProofBack] = useState();
  const [allreadyphoto, setallreadyPhoto] = useState();
  const [allreadyidProofFront, setallreadyIDProofFront] = useState();
  const [allreadyidProofBack, setallreadyIDProofBack] = useState();
  const [gst_number, setGst_number] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [photoStatus, setPhotoStatus] = useState(false);
  const [idFrontStatus, setIdFrontStatus] = useState(false);
  const [idBackStatus, setIdBackStatus] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [onward_arrival, setOnward_arrival] = useState("");
  const [onward_departure, setOnward_departure] = useState("");
  const [travel_mode, setTravel_mode] = useState("");
  const [date_and_time_of_arrival, setDate_and_time_of_arrival] =
    useState(null);
  const [transport_name, setTransport_name] = useState("");
  const [transport_number, setTransport_number] = useState("");
  const [hotel_check_in_date, setHotel_check_in_date] = useState("");
  const [hotel_check_out_date, setHotel_check_out_date] = useState("");
  const [remark, setRemark] = useState("");
  const [return_arrival, setReturn_arrival] = useState("");
  const [return_departure, setReturn_departure] = useState("");
  const [return_travel_mode, setReturn_travel_mode] = useState("Flight");
  const [preffered_train_flight, setPreffered_train_flight] = useState(null);
  const [return_date_and_time_of_arrival, setReturn_date_and_time_of_arrival] =
    useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [showError, setShowError] = useState("");

  const [return_transport_name, setReturn_transport_name] = useState("");
  const [return_train_number, setReturn_train_number] = useState("");
  const [redirectToAddOn, setRedirectToAddOn] = useState(false);
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpStep, setIsOtpStep] = useState(false);
  const [isRotated, setIsRotated] = useState(false);
  const [dataPresent, setDataPresent] = useState({});
  const [city_prefered, setCity_prefered] = useState(null);

  const handleMobileNoChange = (e) => {
    const input = e.target.value;
    const numericValue = input.replace(/\D/g, "");
    setNumber(numericValue);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  function handlePhoto(e) {
    let dataStatus = FormatChecker(e.target.files[0]);
    setPhotoStatus(dataStatus);
    if (!dataStatus) {
      setPhoto(e.target.files[0]);
    } else {
      message.error("please upload in the given format");
    }
  }
  const handleName = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setVisitorName(alphabetOnlyValue);
  };
  const handleCity = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setCity(alphabetOnlyValue);
  };
  const handleState = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setState(alphabetOnlyValue);
  };
  const handleCountry = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setCountry(alphabetOnlyValue);
  };

  const handleNumberSubmit = (e) => {
    e.preventDefault();
    setIsOtpStep(true);
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    setIsRotated(true);
  };

  const handlePostalCode = (e) => {
    const input = e.target.value;
    const numericValue = input.replace(/\D/g, "");
    setPostalCode(numericValue);
  };
  // useEffect(() => {
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_END_POINT}/visitor-category`,
  //       {
  //         id: visitingID,
  //       },
  //       {
  //         headers: {
  //           token: localStorage.getItem("token"),
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log("get-visitor", res.data.data);
  //       setAllCategory(res.data.data);
  //     });
  // }, []);

  function handleIDFront(e) {
    let dataStatus = FormatChecker(e.target.files[0]);
    setIdFrontStatus(dataStatus);
    if (!dataStatus) {
      setIDProofFront(e.target.files[0]);
    } else {
      message.error("please upload in the given format");
    }
  }
  function handleIDBack(e) {
    let dataStatus = FormatChecker(e.target.files[0]);
    setIdBackStatus(dataStatus);
    if (!dataStatus) {
      setIDProofBack(e.target.files[0]);
    } else {
      message.error("please upload in the given format");
    }
  }

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (!emailPattern.test(value)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  const handleCheckNoIsPresentOrNot = () => {
    if (number.length < 10) {
      message.error("Please Enter 10 Digit Mobile Number");
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_API_END_POINT}/check-no-is-present-in-buyer`,
        { number }
      )
      .then((resData) => {
        if (!resData.data.status) {
          message.error(resData.data.message);
        } else {
          setDataPresent(resData.data.data);
          console.log("resData.data.data", resData.data.data);
          setIsRotated(true);
          setVisitorName(resData.data.data.visitor_name);
          setYourCompany(resData.data.data.company_name);
          setGst_number(resData.data.data.gst_number);
          setStreetAddress(resData.data.data.street_address);
          setNumber(resData.data.data.mobile_number);
          setEmail(resData.data.data.email);
          setDesignation(resData.data.data.designation);
          setReturn_travel_mode(resData.data.data?.travel_detail?.travel_mode);
          setDate_and_time_of_arrival(
            resData.data.data?.travel_detail?.arrival_date_time
          );
          setReturn_date_and_time_of_arrival(
            resData.data.data?.travel_detail?.departure_date_time
          );
          setPreffered_train_flight(
            resData.data.data?.travel_detail?.preffered_train_flight
          );
          setallreadyPhoto(resData.data.data?.photo);
          setallreadyIDProofBack(resData.data.data?.id_proof_back);
          setallreadyIDProofFront(resData.data.data?.id_proof_front);
        }
      });
  };

  const handleSubmit = () => {
    console.log(
      "asdasddadaddadadadaasadsdasdasdasdadad",
      "1",
      dataPresent._id,
      "1",
      visitorName,
      "1",
      yourCompany,
      "1",
      gst_number,
      "1",
      streetAddress,
      "1",
      selectedOption,
      "1",
      number,
      "1",
      email,
      "1",
      designation,
      "1",
      return_travel_mode,
      "1",
      date_and_time_of_arrival,
      "1",
      return_date_and_time_of_arrival
    );
    if (
      !visitorName ||
      !yourCompany ||
      !gst_number ||
      !streetAddress ||
      // !selectedOption ||
      !number ||
      !email ||
      !designation ||
      !return_travel_mode ||
      !date_and_time_of_arrival ||
      !return_date_and_time_of_arrival
    ) {
      message.error("All Fields Are Required");
      return;
    }
    if (!isDateInRange(return_date_and_time_of_arrival)) {
      message.error("Select Between DateRange 1 Aug to 3 Aug");
      return;
    }
    if (!isDateInRange(date_and_time_of_arrival)) {
      message.error("Select Between DateRange 1 Aug to 3 Aug");
      return;
    }
    if (!photo && !allreadyphoto) {
      alert("All Fields Are Required");
      return;
    }
    if (!idProofFront && !allreadyidProofFront) {
      alert("All Fields Are Required");
      return;
    }
    if (!idProofBack && !allreadyidProofBack) {
      alert("All Fields Are Required");
      return;
    }
    if (return_travel_mode !== "Self" && !city_prefered) {
      alert("All Fields Are Required");
      return;
    }
    if (return_travel_mode !== "Self" && !preffered_train_flight) {
      message.error("All Fields Are Required");

      return;
    }

    const data = new FormData();
    data.append("visitor_name", visitorName);
    data.append("company_name", yourCompany);
    data.append("gst_number", gst_number);
    data.append("street_address", streetAddress);
    data.append("mobile_number", number);
    data.append("email", email);
    data.append("designation", designation);
    data.append("travel_mode", return_travel_mode);
    data.append("arrival_date_time", date_and_time_of_arrival);
    data.append("departure_date_time", return_date_and_time_of_arrival);
    data.append("photo", photo);
    data.append(
      "preffered_train_flight",
      preffered_train_flight ? preffered_train_flight : ""
    );
    data.append("travel_type", selectedOption);
    data.append("city_prefered", city_prefered);
    data.append("id_proof_front", idProofFront);
    data.append("id_proof_back", idProofBack);
    data.append("_id", dataPresent._id);

    axios
      .post(
        `${process.env.REACT_APP_API_END_POINT}/add-addition-detail`,
        data,
        {
          headers: {
            barcode: dataPresent.barcode,
          },
        }
      )
      .then((resData) => {
        message.success("Changes Saved");
        setIsRotated(false);
      });
  };

  const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  function checkRegex(input) {
    return regex.test(input);
  }

  function convertToUpperCase(input) {
    return input.replace(/[a-z]/gi, (char) => char.toUpperCase());
  }

  const handleGstNumberChange = (e) => {
    setGst_number(convertToUpperCase(e.target.value));
    const value = e.target.value;
    if (checkRegex(value)) {
      console.log("asdasdasd", e.target.value);
      setShowError("");
    } else {
      setShowError("Provide Valid GSTIN");
    }
  };

  useEffect(() => {
    console.log(
      "hello",
      date_and_time_of_arrival,
      isDateInRange(date_and_time_of_arrival)
    );
    if (date_and_time_of_arrival) {
      if (!isDateInRange(date_and_time_of_arrival)) {
        message.error("Select Between DateRange 29 July to 4 Aug");
      }
    }
  }, [date_and_time_of_arrival]);

  useEffect(() => {
    console.log(
      "hello",
      return_date_and_time_of_arrival,
      isDateInRange(return_date_and_time_of_arrival)
    );
    if (return_date_and_time_of_arrival) {
      if (!isDateInRange(return_date_and_time_of_arrival)) {
        message.error("Select Between DateRange 29 July to 4 Aug");
      }
    }
  }, [return_date_and_time_of_arrival]);

  return (
    <>
      {formStatus ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div className="alert alert-warning text-center" role="alert">
            <strong>Form is suspended</strong>
          </div>
        </div>
      ) : (
        <div className="container mt-1">
          <div className="d-flex justify-content-center">
            <div
              className={`card ${isRotated ? "rotate-card" : ""}`}
              // style={{ width: "18re }}
            >
              <div className="card-body">
                {isRotated ? (
                  <>
                    <div>
                      {" "}
                      <>
                        <>
                          <div className="text-center">
                            <img
                              src={MainLogo}
                              className="img-fluid"
                              alt="Main Logo"
                              style={{ height: "80px" }}
                            />

                            <div>
                              <h3 className="form-heading">
                                Buyer Additional Details
                              </h3>
                            </div>
                            <p className="form-heading">One Way Ticket Only</p>
                          </div>

                          <section>
                            <div className="container">
                              <div className="form-body-section">
                                <div className="form-body">
                                  <div className="row">
                                    <div className="form-group col-lg-6 col-md-6">
                                      <label className="fw-bold">
                                        Name <font color="#FF0000">*</font>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control my-2"
                                        placeholder="Name"
                                        value={visitorName}
                                        disabled={
                                          dataPresent?.visitor_name
                                            ? true
                                            : false
                                        }
                                        maxLength={100}
                                        onChange={(e) => {
                                          handleName(e);
                                        }}
                                      />
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6">
                                      <label className="fw-bold">
                                        Company Name{" "}
                                        <font color="#FF0000">*</font>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control my-2"
                                        placeholder="Your Company"
                                        value={yourCompany}
                                        disabled={
                                          dataPresent?.company_name
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          setYourCompany(e.target.value);
                                        }}
                                      />
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6">
                                      <label className="fw-bold">
                                        Gst Number{" "}
                                        <font color="#FF0000">*</font>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control my-2"
                                        placeholder="Gst Number"
                                        value={gst_number}
                                        maxLength={15}
                                        disabled={
                                          dataPresent?.gst_number ? true : false
                                        }
                                        onChange={(e) =>
                                          handleGstNumberChange(e)
                                        }
                                      />
                                      {showError && (
                                        <p className="text-danger">
                                          {showError}
                                        </p>
                                      )}
                                    </div>

                                    <div className="form-group col-lg-6 col-md-6">
                                      <label className="fw-bold">
                                        Address <font color="#FF0000">*</font>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control my-2"
                                        placeholder="Address"
                                        value={streetAddress}
                                        disabled={
                                          dataPresent?.street_address
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          setStreetAddress(e.target.value);
                                        }}
                                      />
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6">
                                      <label className="fw-bold">
                                        Mobile No.{" "}
                                        <font color="#FF0000">*</font>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control my-2"
                                        placeholder="Mobile No."
                                        value={number}
                                        maxLength={10}
                                        disabled={
                                          dataPresent?.mobile_number
                                            ? true
                                            : false
                                        }
                                        onChange={handleMobileNoChange}
                                      />
                                    </div>

                                    <div className="form-group col-lg-6 col-md-6">
                                      <label className="fw-bold">
                                        Email <font color="#FF0000">*</font>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control my-2"
                                        placeholder="Email"
                                        value={email}
                                        disabled={
                                          dataPresent?.email ? true : false
                                        }
                                        onChange={(e) => {
                                          handleEmailChange(e);
                                        }}
                                      />
                                    </div>

                                    <div className="form-group col-lg-6 col-md-6">
                                      <label className="fw-bold">
                                        Designation{" "}
                                        <font color="#FF0000">*</font>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control my-2"
                                        placeholder="Designation"
                                        value={designation}
                                        disabled={
                                          dataPresent?.designation
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          setDesignation(e.target.value);
                                        }}
                                        maxLength={25}
                                      />
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6">
                                      <label className="fw-bold">
                                        Mode of Transport
                                        <font color="#FF0000">*</font>
                                      </label>
                                      <Select
                                        className="my-2"
                                        options={travelArray.map((data) => ({
                                          value: data,
                                          label: data,
                                        }))}
                                        value={
                                          return_travel_mode
                                            ? {
                                                value: return_travel_mode,
                                                label: return_travel_mode,
                                              }
                                            : null
                                        }
                                        onChange={(selectedOption) =>
                                          setReturn_travel_mode(
                                            selectedOption
                                              ? selectedOption.value
                                              : null
                                          )
                                        }
                                        isSearchable
                                        placeholder="Select designation"
                                      />
                                    </div>

                                    {/* <div className="form-group col-lg-6 col-md-6">
                                    <label className="fw-bold">
                                      You are visiting show as
                                    </label>
                                    <Select
                                      className="my-2"
                                      options={visitingAsArray.map((data) => ({
                                        value: data,
                                        label: data,
                                      }))}
                                      value={
                                        visiting_as
                                          ? {
                                              value: visiting_as,
                                              label: visiting_as,
                                            }
                                          : null
                                      }
                                      onChange={(selectedOption) =>
                                        setVisiting_as(
                                          selectedOption
                                            ? selectedOption.value
                                            : null
                                        )
                                      }
                                      isSearchable
                                      placeholder="Select visiting as"
                                    />
                                  </div> */}
                                    <div className="form-group col-lg-6 col-md-6">
                                      <label className="fw-bold">
                                        Arrival Date & Time
                                        <font color="#FF0000">*</font>
                                      </label>
                                      <DateTimerPicker
                                        setTime={setDate_and_time_of_arrival}
                                        time={date_and_time_of_arrival}
                                      />
                                    </div>

                                    <div className="form-group col-lg-6 col-md-6">
                                      <label className="fw-bold">
                                        Departure Date & Time
                                        <font color="#FF0000">*</font>
                                      </label>
                                      <DateTimerPicker
                                        setTime={
                                          setReturn_date_and_time_of_arrival
                                        }
                                        time={return_date_and_time_of_arrival}
                                      />
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <label className="fw-bold">
                                          Upload Photo{" "}
                                          <font color="#FF0000">*</font>
                                          {photoStatus && (
                                            <span className="photo-error">
                                              Only supported (JPG/JPEG/PNG)
                                            </span>
                                          )}
                                        </label>
                                        {allreadyphoto ? (
                                          <div>
                                            <a
                                              href={`${
                                                process.env.REACT_APP_IMAGE_URL
                                              }/photo/${allreadyphoto}?v=${moment().unix()}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              Click here to see the image
                                            </a>
                                          </div>
                                        ) : (
                                          <input
                                            type="file"
                                            onChange={handlePhoto}
                                            className="form-control"
                                            accept=".jpg, .jpeg, .png"
                                            // capture="user"
                                          />
                                        )}
                                      </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 ">
                                      <div className="form-group">
                                        <label className="fw-bold">
                                          ID Proof(front){" "}
                                          <font color="#FF0000">*</font>
                                          {idFrontStatus && (
                                            <span className="photo-error">
                                              Only supported (JPG/JPEG/PNG)
                                            </span>
                                          )}
                                        </label>
                                        {allreadyidProofFront ? (
                                          <div>
                                            <a
                                              href={`${
                                                process.env.REACT_APP_IMAGE_URL
                                              }/id_proof/${allreadyidProofFront}?v=${moment().unix()}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              Click here to see the image
                                            </a>
                                          </div>
                                        ) : (
                                          <input
                                            type="file"
                                            onChange={handleIDFront}
                                            className="form-control"
                                            accept=".jpg, .jpeg, .png"
                                            // capture="environment"
                                          />
                                        )}
                                      </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <label className="fw-bold">
                                          ID Proof(back){" "}
                                          <font color="#FF0000">*</font>
                                          {idBackStatus && (
                                            <span className="photo-error">
                                              Only supported (JPG/JPEG/PNG)
                                            </span>
                                          )}
                                        </label>
                                        {allreadyidProofBack ? (
                                          <div>
                                            <a
                                              href={`${
                                                process.env.REACT_APP_IMAGE_URL
                                              }/id_proof/${allreadyidProofBack}?v=${moment().unix()}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              Click here to see the image
                                            </a>
                                          </div>
                                        ) : (
                                          <input
                                            type="file"
                                            onChange={handleIDBack}
                                            className="form-control"
                                            accept=".jpg, .jpeg, .png"
                                            // capture="environment"
                                          />
                                        )}
                                      </div>
                                    </div>
                                    {return_travel_mode !== "Self" && (
                                      <>
                                        <div className="form-group col-lg-6 col-md-6">
                                          <label className="fw-bold">
                                            Preferred Flight/Train{" "}
                                            <font color="#FF0000">*</font>
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control my-2"
                                            placeholder="Preferred Train Flight"
                                            value={preffered_train_flight}
                                            onChange={(e) => {
                                              setPreffered_train_flight(
                                                e.target.value
                                              );
                                            }}
                                            maxLength={25}
                                          />
                                        </div>
                                        <div className="form-group col-lg-6 col-md-6">
                                          <div>
                                            <label>
                                              <input
                                                type="radio"
                                                value="Arrival"
                                                checked={
                                                  selectedOption === "Arrival"
                                                }
                                                onChange={handleOptionChange}
                                              />
                                              Arrival
                                            </label>
                                          </div>
                                          <div>
                                            <label>
                                              <input
                                                type="radio"
                                                value="Departure"
                                                checked={
                                                  selectedOption === "Departure"
                                                }
                                                onChange={handleOptionChange}
                                              />
                                              Departure
                                            </label>
                                          </div>
                                        </div>
                                        <div className="form-group col-lg-6 col-md-6">
                                          <label className="fw-bold">
                                            {selectedOption === "Departure"
                                              ? "Traveling To"
                                              : "Traveling from"}
                                            <font color="#FF0000">*</font>
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control my-2"
                                            placeholder={
                                              selectedOption === "Departure"
                                                ? "Traveling To"
                                                : "Traveling From"
                                            }
                                            value={city_prefered}
                                            // disabled={
                                            //   dataPresent?.designation ? true : false
                                            // }
                                            onChange={(e) => {
                                              setCity_prefered(e.target.value);
                                            }}
                                            maxLength={30}
                                          />
                                        </div>
                                      </>
                                    )}
                                    <div className="form-submit">
                                      <Button
                                        onClick={() => {
                                          handleSubmit();
                                        }}
                                        variant="outline-secondary"
                                      >
                                        Submit
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </>
                        <Footer />
                      </>
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={MainLogo}
                      className="img-fluid"
                      alt="Main Logo"
                      style={{ height: "80px" }}
                    />

                    <div className="text-center">
                      <h4 className="form-heading">Buyer Additional Details</h4>
                    </div>
                    <form onSubmit={handleNumberSubmit}>
                      <div className="mb-3 text-center mt-2">
                        <label htmlFor="number" className="form-label">
                          Enter Phone Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Buyer Contact Number"
                          id="number"
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                          required
                          maxLength={10}
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => handleCheckNoIsPresentOrNot()}
                      >
                        Submit
                      </button>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BuyerRegistration;

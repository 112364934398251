import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import VisitorForm from "./VisitorForm";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const VendorOwner = () => {
  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  const { barcode } = useParams();
  console.log("sasadsddasdsassdsasdadsdadadsadsadsasdasdasdasdasdasd", barcode);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_END_POINT}/get-registration-visibility`)
      .then((res) => {
        // console.log(
        //   "here is data come from 32 line of the  registration setting ",
        //   res.data
        // );
        // setExhibitorStaff(res.data.exhibitor_staff);
        if (res.data.data.vendor_owner === "active") {
          setShow(true);
        } else {
          setShow(false);
        }
        // setExhibitorVip(res.data.exhibitor_vip);
        // setVisitor(res.data.visitor);
        // setVendorStaff(res.data.vendor_staff);
        // setVendorOwner(res.data.vendor_owner);
        // localStorage.setItem("exhibitorStaff", res.data.data.exhibitor_staff);
        // localStorage.setItem("exhibitorOwner", res.data.data.exhibitor_owner);
        // localStorage.setItem("exhibitorVip", res.data.data.exhibitor_vip);
        // localStorage.setItem("visitor", res.data.data.visitor);
        // localStorage.setItem("vendorStaff", res.data.data.vendor_staff);
        // localStorage.setItem("vendorOwner", res.data.data.vendor_owner);
      })
      .catch((err) => {
        // LoginRedirection("/superadmin/login");
        if (err.response && err.response.status === 401) {
          navigate("/superadmin/login");
          localStorage.removeItem("jff_token");
          localStorage.removeItem("category");
          localStorage.removeItem("permission");
          localStorage.removeItem("user_id");
          localStorage.removeItem("type_of_user");
        }
      });
  }, []);
  return (
    <>
      {show ? (
        <VisitorForm
          formHeader={"Vendor owner registration"}
          formName={"vendor_owner"}
          barcodeId={barcode}
        />
      ) : (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div className="alert alert-warning text-center" role="alert">
            <strong>Form is suspended</strong>
          </div>
        </div>
      )}
    </>
  );
};

export default VendorOwner;

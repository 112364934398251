import { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import MainLogo from "../../assets/image/main-logo.png";
// import MainLogo from "../../assets/image/jgc-new-logo.png";

import Dropdown from "react-bootstrap/Dropdown";

import MainLogo from "../../assets/image/logo-image.png";
import Select from "react-select";

import moment from "moment";

import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ModalBody, Spinner } from "react-bootstrap";
import "../../../src/stylecss/mainpage.css";
import Swiper from "swiper";
import "swiper/css";

import WOW from "wowjs";
import "animate.css/animate.min.css";
import { message } from "antd";
import Printable from "./Printable";

const KceVisitorRegistration = () => {
  const [show, setShow] = useState(true);
  const [number, setNumber] = useState("");
  const [visitorName, setVisitorName] = useState("");
  const [visitorPhoneNumber, setVisitorPhoneNumber] = useState("");
  const [visitorEmailId, setVisitorEmailId] = useState("");
  const [showFormVisibility, setShowFormVisibility] = useState(true);

  const [photo, setPhoto] = useState();
  const [idProofFront, setIDProofFront] = useState();
  const [idProofBack, setIDProofBack] = useState();
  const [category, setCategory] = useState("visitor");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [visitingCard, setVisitingCard] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [comapnyList, setComapnyList] = useState(null);
  const [invitedByCompany, setInvitedByCompany] = useState(null);
  const [visitorListData, setVisitorListData] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");
  const [companyshow, setCompanyShow] = useState(false);
  const [visiting_as, setVisiting_as] = useState("");
  const [agentsshow, setAgentsShow] = useState(false);
  const [gst_number, setGst_number] = useState("");
  const [showError, setShowError] = useState("");
  const handleClose = () => setShow(false);
  const [duplicateError, setDuplicateError] = useState(null);
  const [isChecked, setIsChecked] = useState(true);

  const handleChange = (e) => {
    setIsChecked(!isChecked);
    console.log("asdasdd", isChecked);
  };
  const visitingAsArray = [
    "Buyer",
    "Retailer",
    "Wholesaler",
    "Manufacturer",
    "Agent",
    "Other",
  ];

  let currentLocation = useLocation().pathname;

  // useEffect(() => {
  //   if (currentLocation === "/kce-visitor-registration") {
  //     setShow(true);
  //   }
  // }, []);

  // for navigation
  // const nav = () => {
  //   navigate("/visitor-registration");
  // };

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_API_END_POINT}/get-registration-visibility`)
  //     .then((res) => {
  //       if (res.data.data.visitor === "active") {
  //         setShowFormVisibility(true);
  //       } else {
  //         setShowFormVisibility(false);
  //       }
  //     })
  //     .catch((err) => {
  //       // LoginRedirection("/superadmin/login");
  //       if (err.response && err.response.status === 401) {
  //         navigate("/superadmin/login");
  //         localStorage.removeItem("jff_token");
  //         localStorage.removeItem("category");
  //         localStorage.removeItem("permission");
  //         localStorage.removeItem("user_id");
  //         localStorage.removeItem("type_of_user");
  //       }
  //     });
  // }, []);

  const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  function checkRegex(input) {
    return regex.test(input);
  }

  function convertToUpperCase(input) {
    return input.replace(/[a-z]/gi, (char) => char.toUpperCase());
  }

  const handleGstNumberChange = (e) => {
    setGst_number(convertToUpperCase(e.target.value));
    const value = e.target.value;
    if (checkRegex(value)) {
      console.log("asdasdasd", e.target.value);
      setShowError("");
    } else {
      setShowError("Provide Valid GSTIN");
    }
  };

  useEffect(() => {
    console.log("hello i am here to see the test daeeed", gst_number.length);

    if (gst_number.length === 15 && checkRegex(gst_number)) {
      // setReloadShow(true);
      // ;

      axios
        .get(
          `${process.env.REACT_APP_API_END_POINT}/check-gst-validation/${gst_number}`
        )
        .then((resData) => {
          // setReloadShow(false);
          // console.log(
          //   "hello i am here to see the test daeeed",
          //   resData.data.message,
          //   resData.data.data.lgnm,
          //   resData.data.data.pradr.adr
          // );
          // if (resData.data.message === "GSTIN  found.") {
          console.log("gst find or not", resData.data);
          if (resData.data.flag === false) {
            setShowError(resData.data.message);
            return;
          }
          setShowError("");
          setCompanyName(resData.data.company);
          setAddress(resData.data.address);
          setCity(resData.data.city);
          // }
        });
    }
  }, [gst_number]);

  function handlePhoto(e) {
    setPhoto(e.target.files[0]);
  }
  function handleIDFront(e) {
    setIDProofFront(e.target.files[0]);
  }
  function handleIDBack(e) {
    setIDProofBack(e.target.files[0]);
  }

  function handleCard(e) {
    setVisitingCard(e.target.files[0]);
  }

  function validateGstNumber(gst_number) {
    // If gst_number is not empty, then perform the checks
    if (gst_number && (gst_number.length !== 15 || !checkRegex(gst_number))) {
      return false;
    }
    // If gst_number is empty, skip the checks and return true
    return true;
  }
  const isValid = validateGstNumber(gst_number);
  const handleSubmit = () => {
    console.log(
      "awdasda",
      !gst_number,
      gst_number.length !== 15,
      !checkRegex(gst_number),
      !gst_number && gst_number.length !== 15 && !checkRegex(gst_number)
    );
    if (!visitorName) {
      message.error("Name is mandatory");
    } else if (isChecked) {
      message.error("Please accept the terms & conditions to proceed");
    } else if (!number) {
      message.error("Mobile No. is mandatory");
    } else if (number.length !== 10) {
      message.error("Please Enter 10 Digit Mobile Number");
      setIsLoading(false);
      return;
    } else if (!isValid) {
      message.error("Fill Valid Gst Number");
      setIsLoading(false);
    } else if (!email) {
      message.error("Email is mandatory");
      // } else if (emailError) {
      //   alert("Invalid email format");
      //   setIsLoading(false);
      //   return;
    } else if (!invitedByCompany) {
      message.error("Invited Company mandatory");
    } else if (!visiting_as) {
      message.error("Visiting As Mandatory");
      setIsLoading(false);
    } else if (!companyName) {
      message.error("Company Name is mandatory");
    } else if (!city) {
      message.error("City is mandatory");
    } else if (!address) {
      message.error("Address is mandatory");
    } else if (!photo) {
      message.error("Photo is mandatory");
    } else if (!idProofFront) {
      message.error("ID Proof Front is mandatory");
    } else if (!idProofBack) {
      message.error("ID Proof Back is mandatory");
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("mobile_number", number);
      formData.append("visitor_name", visitorName);
      formData.append("photo", photo);
      formData.append("id_proof_front", idProofFront);
      formData.append("id_proof_back", idProofBack);
      formData.append("gst_number", gst_number);
      formData.append("visiting_as", visiting_as);
      formData.append("invited_exhibitor_id", invitedByCompany.value);
      formData.append("category", category);
      formData.append("email", email);
      formData.append("city", city);
      formData.append("street_address", address);
      formData.append("visiting_card", visitingCard);
      formData.append("company_name", companyName);
      axios
        .post(
          `${process.env.REACT_APP_API_END_POINT}/visitor-registration`,
          formData
        )
        .then((res) => {
          const value = res.data.message;
          if (value === "Buyer" || value === "Visitor") {
            message.error(`This Person Is Allready Presented As ${value}`);
          } else {
            createRazorypayOrder(
              1000,
              value.barcode,
              moment(value.createdAt).format("MMM Do YY")
            );
            setVisitorEmailId(value.email);
            setVisitorListData(value.barcode);
            setVisitorPhoneNumber(value.mobile_number);
            setRegistrationDate(moment(value.createdAt).format("MMM Do YY"));
            setShow(false);
            setSuccessMessage(true);
            setIsLoading(false);
            setNumber("");
          }
        });
    }
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;
    // Use a regular expression to check if the input is a number
    if (/^\d*$/.test(value)) {
      setNumber(value);
      setMobileError("");
    } else {
      setMobileError("Mobile must contain only numbers.");
    }
  };
  const handleContactPersonChange = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setVisitorName(alphabetOnlyValue);
  };
  const handleCity = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setCity(alphabetOnlyValue);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (!emailPattern.test(value)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };
  useEffect(() => {
    // Initialize Swiper
    const mySwiper = new Swiper(".swiper-container", {
      // Swiper options and configuration here
      slidesPerView: 1,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }, []);

  const [isActive, setIsActive] = useState(false);

  // Function to toggle the class
  const toggleClass = () => {
    setIsActive(!isActive);
  };

  // Define the class name based on the state
  const className = isActive ? "our-story-show" : "our-story-hide";
  // Define the button text based on the state
  const buttonText = isActive ? "Know Less" : "Know More";

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const handleMobileNoChange = (e) => {
    const input = e.target.value;
    const numericValue = input.replace(/\D/g, "");
    setNumber(numericValue);

    if (numericValue.length === 10) {
      axios
        .get(
          `${process.env.REACT_APP_API_END_POINT}/check-is-number/${numericValue}/visitor`
        )
        .then((resData) => {
          if (resData.data.status && resData.data.message === "vip") {
            setDuplicateError("vip");
          } else if (resData.data.status) {
            setDuplicateError("true");
          } else {
            setDuplicateError("false");
          }
        });
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_END_POINT}/exhibitor-lists-data`)
      .then((resData) => {
        setComapnyList(resData.data.data);
      });
  }, []);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const createRazorypayOrder = (
    amount,
    barcode,
    time,
    visitorPhoneNumber,
    visitorEmailId
  ) => {
    let data = JSON.stringify({
      amount: amount,
      currency: "INR",
      barcode,
    });
    axios
      .post(`${process.env.REACT_APP_API_END_POINT}/payment-request`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((resData) => {
        console.log("hello i am testd here", resData.data);
        handleRazorpayScreen(
          resData.data.amount,
          barcode,
          time,
          visitorPhoneNumber,
          visitorEmailId
        );
      })
      .catch((error) => {
        console.log("error in payment:=>", error.message);
      });
  };

  const handleRazorpayScreen = async (
    amount,
    barcode,
    time,
    visitorPhoneNumber,
    visitorEmailId
  ) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      message.error("some thing is not good");
      return;
    }
    const options = {
      // key: "rzp_live_vGtDUlX79LRuv8"
      key: process.env.REACT_APP_RAZORPAY_API_KEY,
      amount: amount,
      currency: "INR",
      name: "KCE",
      description: "pay to kce",
      image: "https://cdnkce.samaksh.in/media/logo192.png",
      notes: { barcode },
      handler: function (response) {
        console.log("responserazorpay_payment_id", response);
        axios
          .get(
            `${process.env.REACT_APP_API_END_POINT}/payment/${response.razorpay_payment_id}/${barcode}/success`
          )
          .then((payment_Data) => {
            console.log("tested", payment_Data.data);
          });
        // setResponseId(response.razorpay_payment_id);
        navigate("/payment-successful", {
          state: {
            visitorListData: barcode,
            visitorName,
            registrationDate: time,
          },
        });
      },
      modal: {
        ondismiss: function (response) {
          console.log("responserazorpay_payment_id", response);
          axios
            .get(
              `${process.env.REACT_APP_API_END_POINT}/payment/demo/${barcode}/failed`
            )
            .then((payment_Data) => {
              console.log("tested", payment_Data.data);
            });
          // handle failed or canceled payment
          navigate("/payment-failed", {
            state: {
              visitorListData: barcode,
              visitorName,
              registrationDate: time,
            },
          });
        },
      },
      prefill: {
        name: visitorName,
        email: visitorEmailId,
        contact: visitorPhoneNumber,
      },
      theme: {
        color: "#F4C430",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <>
      {" "}
      <div className="text-center">
        <h1 className="display-1">Welcome</h1>
        <h1 className="display-4">to</h1>
        <img src={MainLogo} className="img-fluid" alt="Main Logo" />
      </div>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: "100%" }}
            >
              <img src={MainLogo} alt="" style={{ maxWidth: "100px" }} />
              <p className="text-center ms-3">Registration</p>
            </div>
          </Modal.Title>
          {/* <Button variant="outline-secondary" onClick={handleClose}>
            Close
          </Button> */}
        </Modal.Header>
        <Modal.Body className="mb-3 ">
          {showFormVisibility ? (
            <div className="row">
              <div className="col-lg-6 col-12 regis-form-group">
                <label className="fw-bold">
                  Mobile No. <font color="#FF0000">*</font>
                </label>
                <input
                  type="text"
                  value={number}
                  placeholder="Mobile"
                  className="form-control my-2"
                  maxLength="10"
                  onChange={handleMobileNoChange}
                  onBlur={() => {
                    setDuplicateError(null);
                  }}
                />
                {duplicateError === "true" && (
                  <p className="text-danger">
                    This Visitor is already registered.
                  </p>
                )}
                {duplicateError === "vip" && (
                  <p className="text-danger">You already Registered as Buyer</p>
                )}
                {duplicateError === "false" && (
                  <p className="text-success">This number is available.</p>
                )}
              </div>
              <div className="col-lg-6 col-12 regis-form-group">
                <label className="fw-bold">Gst Number</label>
                <input
                  type="text"
                  placeholder="Gst Number"
                  value={gst_number}
                  className="form-control my-2"
                  onChange={handleGstNumberChange}
                />
                {showError && <p className="text-danger">{showError}</p>}
              </div>
              <div className="col-lg-6 col-12 regis-form-group">
                <label className="fw-bold">
                  Name <font color="#FF0000">*</font>
                </label>
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control my-2"
                  onChange={(e) => {
                    handleContactPersonChange(e);
                  }}
                />
              </div>

              <div className="col-lg-6 col-12 regis-form-group">
                <label className="fw-bold">
                  Email <font color="#FF0000">*</font>
                </label>
                <input
                  type="text"
                  placeholder="Email"
                  className="form-control my-2"
                  onChange={(e) => handleEmailChange(e)}
                />
              </div>
              <div className="form-group col-lg-6 col-md-12">
                <label className="fw-bold mb-2">
                  Name of the company that invited you{" "}
                  <font color="#FF0000">*</font>
                </label>
                <Select
                  options={
                    comapnyList &&
                    comapnyList.map((data) => ({
                      value: data.value,
                      label: data.label,
                    }))
                  }
                  value={
                    invitedByCompany
                      ? {
                          value: invitedByCompany.value,
                          label: invitedByCompany.label,
                        }
                      : null
                  }
                  onChange={(selectedOption) => {
                    setInvitedByCompany(selectedOption ? selectedOption : null);
                  }}
                  isSearchable
                  placeholder="Select the company"
                />
              </div>

              <div className="form-group col-lg-6 col-12">
                <label className="fw-bold mb-2">
                  You are visiting show as <font color="#FF0000">*</font>
                </label>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="transparent"
                    id="dropdown-basic"
                    className="form-control border my-2"
                  >
                    {visiting_as ? visiting_as : "Select visiting as"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="form-control nodesure"
                    style={{ overflowY: "scroll", maxHeight: "15vh" }}
                  >
                    {visitingAsArray?.map((data, i) => (
                      <Dropdown.Item
                        key={i}
                        value={data}
                        onClick={() => setVisiting_as(data)}
                        className="d-flex justify-content-center"
                      >
                        {data}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="col-lg-6 col-12 regis-form-group">
                <label className="fw-bold">
                  Company Name <font color="#FF0000">*</font>
                </label>
                <input
                  type="text"
                  placeholder="Company Name"
                  className="form-control my-2"
                  value={companyName}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                />
              </div>

              <div className="col-lg-6 col-12 regis-form-group">
                <label className="fw-bold">
                  Address <font color="#FF0000">*</font>
                </label>
                <input
                  type="text"
                  placeholder="Address"
                  className="form-control my-2"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-6 col-12 regis-form-group">
                <label className="fw-bold">
                  City <font color="#FF0000">*</font>
                </label>
                <input
                  type="text"
                  placeholder="City"
                  className="form-control my-2"
                  value={city}
                  onChange={(e) => {
                    handleCity(e);
                  }}
                />
              </div>
              <div className="col-lg-6 col-12 regis-form-group">
                <label className="fw-bold">
                  Upload Pic <font color="#FF0000">*</font>
                </label>
                <input
                  type="file"
                  className="form-control my-2"
                  accept=".jpg, .jpeg, .png"
                  onChange={handlePhoto}
                />
              </div>
              <div className="col-lg-6 col-12 regis-form-group">
                <label className="fw-bold">
                  Upload ID Front <font color="#FF0000">*</font>
                </label>
                <input
                  type="file"
                  id="idfront"
                  className="form-control my-2"
                  accept=".jpg, .jpeg, .png"
                  onChange={handleIDFront}
                />
              </div>
              <div className="col-lg-6 col-12 regis-form-group mb-3">
                <label className="fw-bold">
                  Upload ID Back <font color="#FF0000">*</font>
                </label>
                <input
                  type="file"
                  id="idback"
                  className="form-control my-2"
                  onChange={handleIDBack}
                  accept=".jpg, .jpeg, .png"
                />
              </div>
              <div className="form-check">
                <label>
                  <input
                    type="checkbox"
                    checked={!isChecked}
                    onChange={handleChange}
                  />
                  <span className="ms-2">
                    I agree to pay the registration fee of ₹ 1000/-
                  </span>
                </label>
              </div>
              <Printable />
              <div className="col-12 form-group mb-3">
                <Button
                  type="button"
                  className="regis w-100"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Register"
                  )}
                </Button>
              </div>
            </div>
          ) : (
            <div className="container text-center my-5">
              <div className="card shadow-lg p-3 mb-5 bg-white rounded">
                <div className="card-body">
                  <h1 className="card-title text-danger">
                    Visitor Registration Suspended
                  </h1>
                  <p className="card-text text-muted">
                    We apologize for the inconvenience. The visitor registration
                    is currently suspended. Please check back later.
                  </p>
                  <div className="mt-4">
                    <a href="/" className="btn btn-success">
                      Go to Homepage
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      {/* {successMessage && (
        <>
          <Modal
            size="md"
            show={() => setSuccessMessage(true)}
            onHide={() => setSuccessMessage(false)}
            centered
            backdrop="static"
          >
            <Modal.Header>
              <Modal.Title>
                <img src={MainLogo} alt="" style={{ maxWidth: "35px" }} /> Form
                Submited Successfully
              </Modal.Title>
              <Button
                variant="outline-secondary"
                onClick={() => setSuccessMessage(false)}
              >
                Close
              </Button>
            </Modal.Header>

            <Modal.Body className="regis-greetings-bg">
              {visitorListData && (
                <>
                  <div className="register-greetings ps-5 pe-2 pb-4 pt-2">
                    <span>
                      Kolkata Couture Expo (KCE) Registration Confirmation
                    </span>
                    <span>
                      Dear <b>{visitorName}</b>,
                    </span>

                    <div className="register-details">
                      <span className="text-decoration-underline mb-4">
                        Here are your Registration details:{" "}
                      </span>
                      <span>
                        User Name:
                        <b> {visitorName}</b>
                      </span>
                      <span>
                        Registration Number: <b> {visitorListData}</b>
                      </span>
                      <span>
                        Registration Date: <b> {registrationDate}</b>
                      </span>
                    </div>
                    <span className="mt-3">Best regards,</span>
                    <span>Kolkata Couture Expo Team</span>
                
                  </div>
                </>
              )}
            </Modal.Body>
          </Modal>
        </>
      )} */}
    </>
  );
};

export default KceVisitorRegistration;

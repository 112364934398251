import { Select } from "antd";
import React, { useEffect, useState } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";

const DateTimerPicker = ({ time, setTime }) => {
  return (
    <div className="my-2"> 
     {time && <div style={{ display: "flex", alignItems: "center" ,marginBottom:'-13px',color:'grey'}}>
        <p style={{ marginRight: "10px" }}>Selected Time: {time}</p>
      </div>}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["MobileDateTimePicker"]}>
          <MobileDateTimePicker
            onChange={(date) => {
              const parsedDate = new Date(date);
              setTime(moment(parsedDate.getTime()).format("lll"));

              console.log("asdasd");
            }}
          />
          {/* <DateTimePicker
                                onChange={(date) => {
                                  const parsedDate = new Date(date)

                                  // console.log('asdasd', parsedDate.getTime());
                                  setTask((prevTask) => ({
                                    ...prevTask,
                                    date: parsedDate.getTime(), // Replace 'yourTypeValue' with the desired value
                                  }))
                                }}
                              /> */}
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
};

export default DateTimerPicker;

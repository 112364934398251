import React from "react";
const AdminInvitedVIPGuestList = React.lazy(() =>
  import("../components/AdminVIP/AdminInvitedVIPGuestList")
);
const AdminVIPChangePassword = React.lazy(() =>
  import("../components/AdminVIP/AdminVIPChangePassword")
);
const DashBoard = React.lazy(() =>
  import("../components/superAdmin/dashboard/DashBoard")
);
const FileManager = React.lazy(() =>
  import("../components/superAdmin/FileManager/FileManager")
);
const AddArea = React.lazy(() =>
  import("../components/superAdmin/area/AddArea")
);
const AreaList = React.lazy(() =>
  import("../components/superAdmin/area/AreaList")
);
const AddExhibitor = React.lazy(() =>
  import("../components/superAdmin/exhibitor/Exhibitor")
);
const ExhibitorList = React.lazy(() =>
  import("../components/superAdmin/exhibitor/ExhibitorList")
);
const ExhibitorReport = React.lazy(() =>
  import("../components/superAdmin/exhibitor/ExhibitorReport")
);
const VipGuestList = React.lazy(() =>
  import("../components/superAdmin/exhibitor/VipGuestList")
);
const InvitedVipGuestList = React.lazy(() =>
  import("../components/superAdmin/exhibitor/InvitedVipGuestList")
);
const RecycledVip = React.lazy(() =>
  import("../components/superAdmin/exhibitor/RecycledVip")
);
const AddVendor = React.lazy(() =>
  import("../components/superAdmin/vendor/AddVendor")
);
const VenderList = React.lazy(() =>
  import("../components/superAdmin/vendor/VenderList")
);
const VisiterRequestList = React.lazy(() =>
  import("../components/superAdmin/visitor/VisiterRequestList")
);
const AcceptedVisitorList = React.lazy(() =>
  import("../components/superAdmin/visitor/AcceptedVisitorList")
);
const HoldVisitorList = React.lazy(() =>
  import("../components/superAdmin/visitor/HoldVisitorList")
);
const RejectedVisitorList = React.lazy(() =>
  import("../components/superAdmin/visitor/DeleteVisitorList")
);
const AgentList = React.lazy(() =>
  import("../components/superAdmin/visitor/AgentList")
);
const ApprovedAgentList = React.lazy(() =>
  import("../components/superAdmin/visitor/ApprovedAgentList")
);
const HotelRegistration = React.lazy(() =>
  import("../components/superAdmin/Hotels/HotelRegistration")
);

const ChangePassword = React.lazy(() =>
  import("../components/superAdmin/Settings/ChangePassword")
);
const DataExtracter = React.lazy(() =>
  import("../components/superAdmin/Settings/DataExtracter")
);
const DownloadPics = React.lazy(() =>
  import("../components/superAdmin/Settings/DownloadPics")
);
const EmailSMSSetting = React.lazy(() =>
  import("../components/superAdmin/Settings/EmailSMSSetting")
);
const RegistrationSetting = React.lazy(() =>
  import("../components/superAdmin/Settings/RegistrationSetting")
);

const VendorOwnerList = React.lazy(() =>
  import("../components/superAdmin/vendor/ownerListVendor")
);
const VendorStaffList = React.lazy(() =>
  import("../components/superAdmin/vendor/StaffList")
);

// Exhibitor Start
const ExhibitorDashboardContent = React.lazy(() =>
  import(
    "../components/exhibitor/exhibitor_dashboard/ExhibitorDashboardContent"
  )
);
const ExhibitorOwnerList = React.lazy(() =>
  import("../components/exhibitor/ExhibitorOwnerList/ExhibitorOwnerList")
);
const ExhibitorStaffList = React.lazy(() =>
  import("../components/exhibitor/StaffList/StaffList")
);

const ExhibitorModalLists = React.lazy(() =>
  import("../components/exhibitor/ExhibitorModalList/ExhibitorModalList")
);

const ExhibitorFamilyLists = React.lazy(() =>
  import("../components/exhibitor/ExhibitorfamilyList/ExhibitorFamilyList")
);

const ExhibitorVIPGuestList = React.lazy(() =>
  import("../components/exhibitor/VIPGuest/VIPGuestList")
);
const ExhibitorChangePassword = React.lazy(() =>
  import("../components/exhibitor/Form/ChangePassword")
);

const ExhibitorRecycleVIPGuestList = React.lazy(() =>
  import("../components/exhibitor/VIPGuest/RecycledVIPGuestList")
);
const EditForm = React.lazy(() => import("../components/visitorform/EditForm"));

const ExhibitorVIPGuestRequestList = React.lazy(() =>
  import("../components/exhibitor/VIPGuest/VIPGuestRequestList")
);

//hospitality
const HospitalityList = React.lazy(() =>
  import("../components/Hospitality/HospitalityList")
);

const HospitalityPasswordChange = React.lazy(() =>
  import("../components/Hospitality/HospitalityPasswordChange")
);

const CabServiceList = React.lazy(() =>
  import("../components/CabService/VipList")
);
const FlightServiceList = React.lazy(() =>
  import("../components/FlightService/FlightServiceList")
);
const FlightServiceChangePassword = React.lazy(() =>
  import("../components/FlightService/FlightServiceChangePassword")
);

const VisitorCategory = React.lazy(() =>
  import("../components/superAdmin/visitor/VisitorCategory")
);

const CabServiceChangePassword = React.lazy(() =>
  import("../components/CabService/CabServicePasswordChange")
);

const ExhibitorStaffRegistration = React.lazy(() =>
  import("../components/visitorform/ExhibitorStaff")
);
const ExhibitorOwnerRegistration = React.lazy(() =>
  import("../components/visitorform/ExhibitorOwner")
);
const ExhibitorModalRegistration = React.lazy(() =>
  import("../components/visitorform/Exhibitormodal")
);

const ExhibitorFamilyRegistration = React.lazy(() =>
  import("../components/visitorform/ExhibitorFamily")
);
const ExhibitorVIPRegistration = React.lazy(() =>
  import("../components/visitorform/ExhibitorVip")
);
const BuyerAdminDahboard = React.lazy(() =>
  import("../components/BuyerAdmin/BuyerAdminDashboard")
);
const BuyerRequestList = React.lazy(() =>
  import("../components/BuyerAdmin/BuyerRequestList")
);
const BuyerRecycleList = React.lazy(() =>
  import("../components/BuyerAdmin/BuyerRecycleList")
);

const VisitorAdminDahboard = React.lazy(() =>
  import("../components/visitorAdmin/VisitorAdminDashboard")
);
const VisitorApprovedList = React.lazy(() =>
  import("../components/visitorAdmin/VisitorApprovedList")
);
// VisitorHoldList;
const VisitorHoldList = React.lazy(() =>
  import("../components/visitorAdmin/VisitorHoldList")
);
const VIsitorRejectList = React.lazy(() =>
  import("../components/visitorAdmin/VIsitorRejectList")
);

const VisitorRequestList = React.lazy(() =>
  import("../components/visitorAdmin/VisitorRequestList")
);
// getting category from localStorage
// const category = localStorage.getItem('category')

// let superAdmin = false
// let adminVip = false
// let exhibitor = false

// if (category === "super_admin") {
//   superAdmin = true
// }
// else if (category === "vip_admin") {
//   adminVip = true
// }
// else if (category === "exhibitor") {
//   exhibitor = true
// }

// Admin Start

const User = React.lazy(() => import("../views/admin/Users"));
const Role = React.lazy(() => import("../views/admin/Roles"));
const Permission = React.lazy(() => import("../views/admin/Permission"));

const VisitorForm = React.lazy(() =>
  import("../components/HtmlMainPage/MainPage")
);

// Admin End

const routes = [
  //..................................................................frontend page.............................................

  // Super admin start
  {
    path: "/superadmin/dashboard",
    exact: true,
    booleanValue: "super_admin",
    element: DashBoard,
  },
  {
    path: "/superadmin/file-manager",
    exact: true,
    booleanValue: "super_admin",
    element: FileManager,
  },
  {
    path: "/superadmin/add-area",
    exact: true,
    booleanValue: "super_admin",
    element: AddArea,
  },
  {
    path: "/superadmin/area-list",
    exact: true,
    booleanValue: "super_admin",
    element: AreaList,
  },
  {
    path: "/superadmin/add-exhibitor",
    exact: true,
    booleanValue: "super_admin",
    element: AddExhibitor,
  },
  {
    path: "/superadmin/exhibitor-list",
    exact: true,
    booleanValue: "super_admin",
    element: ExhibitorList,
  },
  {
    path: "/superadmin/exhibitor-report",
    exact: true,
    booleanValue: "super_admin",
    element: ExhibitorReport,
  },
  {
    path: "/superadmin/vip-guest",
    exact: true,
    booleanValue: "super_admin",
    element: VipGuestList,
  },
  {
    path: "/superadmin/invited-vip-guest",
    exact: true,
    booleanValue: "super_admin",
    element: InvitedVipGuestList,
  },
  {
    path: "/superadmin/recycled-vip",
    exact: true,
    booleanValue: "super_admin",
    element: RecycledVip,
  },
  {
    path: "/superadmin/add-vender",
    exact: true,
    booleanValue: "super_admin",
    element: AddVendor,
  },
  {
    path: "/superadmin/vender-list",
    exact: true,
    booleanValue: "super_admin",
    element: VenderList,
  },
  {
    path: "/superadmin/vis-req-li",
    exact: true,
    booleanValue: "super_admin",
    element: VisiterRequestList,
  },
  {
    path: "/superadmin/acc-vis-li",
    exact: true,
    booleanValue: "super_admin",
    element: AcceptedVisitorList,
  },
  {
    path: "/superadmin/hol-vis-li",
    exact: true,
    booleanValue: "super_admin",
    element: HoldVisitorList,
  },
  {
    path: "superadmin/rej-vis-li",
    exact: true,
    booleanValue: "super_admin",
    element: RejectedVisitorList,
  },
  {
    path: "/superadmin/age-li",
    exact: true,
    booleanValue: "super_admin",
    element: AgentList,
  },
  {
    path: "/superadmin/app-age-li",
    exact: true,
    booleanValue: "super_admin",
    element: ApprovedAgentList,
  },
  // {
  //   path: "/superadmin/spot-registration",
  //   exact: true,
  //   booleanValue: "super_admin",
  //   element: SpotRegistration,
  // },
  {
    path: "/superadmin/hotel-registration",
    exact: true,
    booleanValue: "super_admin",
    element: HotelRegistration,
  },
  {
    path: "/superadmin/change-password",
    exact: true,
    booleanValue: "super_admin",
    element: ChangePassword,
  },
  {
    path: "/superadmin/data-extracter",
    exact: true,
    booleanValue: "super_admin",
    element: DataExtracter,
  },
  {
    path: "/superadmin/download-pics",
    exact: true,
    booleanValue: "super_admin",
    element: DownloadPics,
  },
  {
    path: "/superadmin/email-sms-setting",
    exact: true,
    booleanValue: "super_admin",
    element: EmailSMSSetting,
  },
  {
    path: "/superadmin/registration-setting",
    exact: true,
    booleanValue: "super_admin",
    element: RegistrationSetting,
  },
  {
    path: "/superadmin/vendor/staff-list",
    exact: true,
    booleanValue: "super_admin",
    element: VendorStaffList,
  },
  {
    path: "/superadmin/vendor/owner-list",
    exact: true,
    booleanValue: "super_admin",
    element: VendorOwnerList,
  },
  {
    path: "/superadmin/editform",
    exact: true,
    booleanValue: "super_admin",
    element: EditForm,
  },

  {
    path: "/superadmin/category",
    exact: true,
    booleanValue: "super_admin",
    element: VisitorCategory,
  },

  {
    path: "/registration",
    exact: true,
    // booleanValue: "super_admin",
    element: VisitorForm,
  },
  {
    path: "/exhibitor/exhibitor-staff-registration",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorStaffRegistration,
  },
  {
    path: "/exhibitor/exhibitor-modal-registration",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorModalRegistration,
  },
  {
    path: "/exhibitor/exhibitor-family-registration",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorFamilyRegistration,
  },
  {
    path: "/exhibitor/vip-guest-requrest-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorVIPGuestRequestList,
  },
  {
    path: "/exhibitor/exhibitor-vip-registration",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorVIPRegistration,
  },
  {
    path: "/exhibitor/exhibitor-owner-registration",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorOwnerRegistration,
  },
  {
    path: "/exhibitor/dashboard",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorDashboardContent,
  },
  {
    path: "/exhibitor/owner-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorOwnerList,
  },
  {
    path: "/exhibitor/staff-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorStaffList,
  },

  {
    path: "/exhibitor/modal-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorModalLists,
  },
  {
    path: "/exhibitor/family-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorFamilyLists,
  },
  {
    path: "/exhibitor/vip-guest-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorVIPGuestList,
  },
  {
    path: "/exhibitor/change-password",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorChangePassword,
  },
  {
    path: "/exhibitor/recycle-vip-guest-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorRecycleVIPGuestList,
  },

  //agent

  {
    path: "/agent/agent-staff-registration",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorStaffRegistration,
  },
  {
    path: "/agent/agent-modal-registration",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorModalRegistration,
  },
  {
    path: "/agent/agent-family-registration",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorFamilyRegistration,
  },
  {
    path: "/agent/vip-guest-requrest-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorVIPGuestRequestList,
  },
  {
    path: "/agent/agent-vip-registration",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorVIPRegistration,
  },
  {
    path: "/agent/agent-owner-registration",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorOwnerRegistration,
  },
  {
    path: "/agent/dashboard",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorDashboardContent,
  },
  {
    path: "/agent/owner-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorOwnerList,
  },
  {
    path: "/agent/staff-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorStaffList,
  },

  {
    path: "/agent/modal-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorModalLists,
  },
  {
    path: "/agent/family-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorFamilyLists,
  },
  {
    path: "/agent/vip-guest-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorVIPGuestList,
  },
  {
    path: "/agent/change-password",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorChangePassword,
  },
  {
    path: "/agent/recycle-vip-guest-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorRecycleVIPGuestList,
  },

  //sponser

  {
    path: "/sponser/sponser-staff-registration",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorStaffRegistration,
  },
  {
    path: "/sponser/sponser-modal-registration",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorModalRegistration,
  },
  {
    path: "/sponser/sponser-family-registration",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorFamilyRegistration,
  },
  {
    path: "/sponser/vip-guest-requrest-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorVIPGuestRequestList,
  },
  {
    path: "/sponser/sponser-vip-registration",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorVIPRegistration,
  },
  {
    path: "/sponser/sponser-owner-registration",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorOwnerRegistration,
  },
  {
    path: "/sponser/dashboard",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorDashboardContent,
  },
  {
    path: "/sponser/owner-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorOwnerList,
  },
  {
    path: "/sponser/staff-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorStaffList,
  },

  {
    path: "/sponser/modal-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorModalLists,
  },
  {
    path: "/sponser/family-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorFamilyLists,
  },
  {
    path: "/sponser/vip-guest-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorVIPGuestList,
  },
  {
    path: "/sponser/change-password",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorChangePassword,
  },
  {
    path: "/sponser/recycle-vip-guest-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorRecycleVIPGuestList,
  },



   //associate

  {
    path: "/associate/associate-staff-registration",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorStaffRegistration,
  },
  {
    path: "/associate/associate-modal-registration",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorModalRegistration,
  },
  {
    path: "/associate/associate-family-registration",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorFamilyRegistration,
  },
  {
    path: "/associate/vip-guest-requrest-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorVIPGuestRequestList,
  },
  {
    path: "/associate/associate-vip-registration",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorVIPRegistration,
  },
  {
    path: "/associate/associate-owner-registration",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorOwnerRegistration,
  },
  {
    path: "/associate/dashboard",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorDashboardContent,
  },
  {
    path: "/associate/owner-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorOwnerList,
  },
  {
    path: "/associate/staff-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorStaffList,
  },

  {
    path: "/associate/modal-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorModalLists,
  },
  {
    path: "/associate/family-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorFamilyLists,
  },
  {
    path: "/associate/vip-guest-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorVIPGuestList,
  },
  {
    path: "/associate/change-password",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorChangePassword,
  },
  {
    path: "/associate/recycle-vip-guest-list",
    exact: true,
    booleanValue: "exhibitor",
    element: ExhibitorRecycleVIPGuestList,
  },

  // { path: "/vendor-owner-registration", exact: true, booleanValue: "super_admin", element: VendorOwner },
  // { path: "/vendor-staff-registration", exact: true, booleanValue: "super_admin", element: VendorStaff },
  // { path: "/vip-guest-registration", exact: true, booleanValue: "super_admin", element: ExhibitorVip },
  // Super admin end

  // Admin VIP Start
  {
    path: "/adminvip/exhibitor-list",
    exact: true,
    booleanValue: "vip_admin",
    element: AdminInvitedVIPGuestList,
  },
  {
    path: "/adminvip/change-password",
    exact: true,
    booleanValue: "vip_admin",
    element: AdminVIPChangePassword,
  },
  // Admin VIP End

  // Hopitality Start
  {
    path: "/hospitality/hotel-detail",
    exact: true,
    booleanValue: "hospitality",
    element: HospitalityList,
  },
  {
    path: "/hospitality/change-password",
    exact: true,
    booleanValue: "hospitality",
    element: HospitalityPasswordChange,
  },
  // Hopitality End

  // Cab Service Start
  {
    path: "/cab-service/cab-detail",
    exact: true,
    booleanValue: "cab_service",
    element: CabServiceList,
  },
  {
    path: "/cab-service/change-password",
    exact: true,
    booleanValue: "cab_service",
    element: CabServiceChangePassword,
  },
  // Cab Service End

  // Flight Service Start
  {
    path: "/flight-service/flight-details",
    exact: true,
    booleanValue: "flight_details",
    element: FlightServiceList,
  },
  {
    path: "/flight-service/change-password",
    exact: true,
    booleanValue: "flight_details",
    element: FlightServiceChangePassword,
  },
  // Flight Service End

  // Exhibitor Start

  // Exhibitor End

  // Admin Start
  {
    path: "/superadmin/user",
    exact: true,
    booleanValue: "super_admin",
    element: User,
  },
  {
    path: "/superadmin/role",
    exact: true,
    booleanValue: "super_admin",
    element: Role,
  },
  {
    path: "/superadmin/permission",
    exact: true,
    booleanValue: "super_admin",
    element: Permission,
  },
  {
    path: "/buyer-admin/dashboard",
    exact: true,
    booleanValue: "buyer_admin",
    element: BuyerAdminDahboard,
  },
  {
    path: "/buyer-admin/req-list",
    exact: true,
    booleanValue: "buyer_admin",
    element: BuyerRequestList,
  },
  {
    path: "/buyer-admin/recycle-list",
    exact: true,
    booleanValue: "buyer_admin",
    element: BuyerRecycleList,
  },

  // visitor_admin
  {
    path: "/visitor-admin/dashboard",
    exact: true,
    booleanValue: "visitor_admin",
    element: VisitorAdminDahboard,
  },
  {
    path: "/visitor-admin/req-list",
    exact: true,
    booleanValue: "visitor_admin",
    element: VisitorRequestList,
  },
  {
    path: "/visitor-admin/hold-list",
    exact: true,
    booleanValue: "visitor_admin",
    element: VisitorHoldList,
  },
  {
    path: "/visitor-admin/approved-list",
    exact: true,
    booleanValue: "visitor_admin",
    element: VisitorApprovedList,
  },
  {
    path: "/visitor-admin/reject-list",
    exact: true,
    booleanValue: "visitor_admin",
    element: VIsitorRejectList,
  },
  // Admin End
];

export default routes;

import React from "react";
import CommonLogin from '../../common/CommonLogin'

function VisitorAdminLogin() {


  return (
    <>
      <CommonLogin
        header={"Welcome to Visitor Admin"}
        afterLogin={"/visitor-admin/dashboard"}
        loginFailed={"/visitor-admin/login"}
        prem_name={"visitor_admin"}
      />
    </>
  );
}

export default VisitorAdminLogin;



import React from "react";
import MainLogo from "../../assets/image/logo-image.png";

const FrontPage = () => {
  return (
    <div className="text-center">
      <h1 className="display-1">Welcome</h1>
      <h1 className="display-4">to</h1>
      <img src={MainLogo} className="img-fluid" alt="Main Logo" />
    </div>
  );
};

export default FrontPage;

import React, { useEffect, useState } from "react";
import VisitorForm from "./VisitorForm";
import axios from "axios";
import ExhibitorDashboardNavbar from "../exhibitor/exhibitor_dashboard/ExhibitorDashboardNavbar";
import { useNavigate } from "react-router-dom";

const ExhibitorVip = () => {
  const [show, setShow] = useState(false);
  const [formSuspension, setFormSuspension] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_END_POINT}/get-registration-visibility`)
      .then((res) => {
        console.log(
          "here is data come from 32 line of the  registration setting ",
          res.data
        );
        // setExhibitorStaff(res.data.exhibitor_staff);
        if (res.data.data.exhibitor_vip === "active") {
          setFormSuspension(true);
        } else {
          setFormSuspension(false);
        }
        // setExhibitorVip(res.data.exhibitor_vip);
        // setVisitor(res.data.visitor);
        // setVendorStaff(res.data.vendor_staff);
        // setVendorOwner(res.data.vendor_owner);
        // localStorage.setItem("exhibitorStaff", res.data.data.exhibitor_staff);
        // localStorage.setItem("exhibitorOwner", res.data.data.exhibitor_owner);
        // localStorage.setItem("exhibitorVip", res.data.data.exhibitor_vip);
        // localStorage.setItem("visitor", res.data.data.visitor);
        // localStorage.setItem("vendorStaff", res.data.data.vendor_staff);
        // localStorage.setItem("vendorOwner", res.data.data.vendor_owner);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          navigate("/superadmin/login");
          localStorage.removeItem("jff_token");
          localStorage.removeItem("category");
          localStorage.removeItem("permission");
          localStorage.removeItem("user_id");
          localStorage.removeItem("type_of_user");
        }
      });
  }, []);
  return (
    <>
      <>
        <ExhibitorDashboardNavbar />
        {!formSuspension ? (
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="alert alert-warning text-center" role="alert">
              <strong>Form is suspended</strong>
            </div>
          </div>
        ) : (
          <VisitorForm
            formHeader={"Buyer Registration"}
            formName={"exhibitor_vip"}
            barcodeId={""}
          />
        )}
      </>
    </>
  );
};

export default ExhibitorVip;

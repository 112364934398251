import React from "react";

const Printable = () => {
  return (
    <div className="card-body">
      <div style={{ fontSize: "0.7rem" }}>
        <ul>
          <li className="text-danger">
            Registration charges Rs 1000/- (which includes entry charges &
            Lunch)
          </li>
          <li className="text-danger">
            Your registration is subject to approval by KCE 24
          </li>
          <li className="text-danger">
            Your amount will be refunded in the original payment mode if not
            approved.
          </li>
          <li className="text-danger">
            Your payment does not confirm your registration.
          </li>
          <li className="text-danger">Rights of registration reserved</li>
        </ul>
      </div>
      {/* <div className="checkmark-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="120"
                  height="120"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="green"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="bi bi-check-circle"
                >
                  <circle cx="12" cy="12" r="10" />
                  <path d="M9 12l2 2 4-4" />
                </svg>
              </div> */}
    </div>
  );
};

export default Printable;

import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Descriptions, message } from "antd";
import { useNavigate } from "react-router-dom";

const PaymentComponent = () => {
  const [paymentError, setPaymentError] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState("");
  const [url, setUrl] = useState("");
  const [responseId, setResponseId] = useState("");
  const [responseState, setReponseState] = useState("");
  

  const paymentFetch = (e) => {
    e.preventDefault();
    const paymentId = e.target.paymentId.value;
    // a
  };
  // useEffect(() => {
  //   createRazorypayOrder(100); // Automatically create order for 100 Rupees when the component loads
  // }, []);
  return (
    <div>
    
    </div>
  );
};

export default PaymentComponent;
